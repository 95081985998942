<template>
  <div class="home-wrap">
    <div class="banner">
      <div class="header" :class="isFixed ? 'fixed' : ''">
        <img v-if="!isFixed" class="logo2" src="/img/logo2.png" alt="" />
        <img v-if="isFixed" class="logo2" src="/img/logo1.png" alt="" />
        <img
          v-if="!isFixed"
          class="list-icon"
          @click="drawer = true"
          src="https://fe-1301391939.cos.ap-shanghai.myqcloud.com/liuhe/neww/list-icon.png"
          alt=""
        />
        <img
          v-if="isFixed"
          class="list-icon"
          @click="drawer = true"
          src="https://fe-1301391939.cos.ap-shanghai.myqcloud.com/liuhe/neww/list-icon2.png"
          alt=""
        />
      </div>
      <div class="slogan">一站式AI学习与实训平台</div>
      <div class="slogan-desc">
        <div class="slogan-title">全栈AI，学习实训一站到位</div>
        <div class="slogan-content">
          <div>
            <img src="/img/slogan-icon1.png" alt="" />
            起来：一站式接入海内外 20 多个AI大模型
          </div>
          <div>
            <img src="/img/slogan-icon2.png" alt="" />
            起来：超过 100 门AIGC课程
          </div>
          <div>
            <img src="/img/slogan-icon3.png" alt="" />
            起来：超过 1000 家企业客户正在使用
          </div>
        </div>
      </div>
      <div class="stats-bar">
        <div class="stat-item" v-for="item in stats" :key="item.label">
          <div class="stat-value">
            {{ item.value }}<span style="font-size: 12px">{{ item.unit }}</span>
          </div>
          <div class="stat-label">{{ item.label }}</div>
        </div>
      </div>

      <div class="try-btn" @click="toLiuhe()">免费试用</div>
    </div>

    <div class="model" id="model">
      <div class="title">海内外主流大模型接入</div>
      <div class="title-desc">众多模型随手拈来，随时随地稳定使用</div>
      <img
        width="100%"
        style="margin-top: 27px"
        src="/img/mobile/model.png"
        alt=""
      />
    </div>
    <div class="AIcourse" id="AIcourse">
      <div class="title">上百门AIGC课程</div>
      <div class="title-desc">职场人的能力充电站，从点到面构建完整知识体系</div>
      <div class="course-content">
        <img src="/img/mobile/course1.png" alt="" />
        <div class="content">
          <div class="course-title">AIGC Studio AI通识精品课</div>
          <div class="course-desc">从0基础到进阶深度学习</div>
          <div class="lecturer">金牌讲师</div>
          <div class="lecturer-info">
            <img src="/img/course3.png" alt="" />
            <ul class="info-box">
              <li>AIGC Studio合伙人</li>
              <li>中关村人工智能学院高级顾问</li>
              <li>前阿里巴巴达摩院高级技术专家</li>
              <li>前百度高级技术专家</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="course">
        <div
          v-for="course in courses"
          :style="{ backgroundImage: `url(${course.url})` }"
        >
          <span>{{ course.name }}系列课程</span>
        </div>
      </div>
      <div class="try-btn-wrap">
        <div class="try-btn" @click="toLiuhe()">学习课程</div>
      </div>
      <div class="second-title">「 讲师团队 」</div>
      <div class="team-members">
        <div class="member-card" v-for="member in members" :key="member.name">
          <img :src="member.image" alt="member photo" />
          <div class="member-info">
            <div class="info" v-html="member.description"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业服务 -->
    <div class="service" id="service">
      <div class="title">企业数字智能化服务</div>
      <div class="title-desc" style="margin-bottom: 20px">
        开启智能新时代，企业创新驱动力
      </div>
      <!-- <div class="second-title">「 企业轻量级私有化多模态大模型 」</div> -->
      <div class="service-bg">
        <div class="mreeygo">
          <div class="title">超1000家企业客户都在用</div>
          <marquee behavior="alternate" scrollamount="5" direction="left" loop>
            <img src="/img/merry3.png" alt="" />
          </marquee>
          <marquee behavior="alternate" scrollamount="5" direction="right" loop>
            <img src="/img/merry4.png" alt="" />
          </marquee>
        </div>
      </div>
      <div class="second-title">「 提供标准化的行情轻模型，支持按需定制 」</div>
      <div class="feature">
        <div class="feature-card" v-for="(item, i) in features" :key="i">
          <div class="icon-wrapper">
            <img :src="item.icon" />
          </div>
          <h3>{{ item.title }}</h3>
          <p>{{ item.description }}</p>
        </div>
      </div>
      <!-- <div class="try-btn-wrap"> -->
      <div class="try-btn">
        <a href="#contactUs">定制模型 </a>
      </div>
      <!-- </div> -->
    </div>
    <!-- 场景 -->
    <div class="scenario" id="scenario">
      <div class="title">应用场景</div>
      <div class="title-desc">智能应用，打造丰富AI应用场景的无限可能</div>
      <div class="mobile-page">
        <div class="scenario-wrap" v-for="item in scenario" :key="item.title">
          <img :src="item.icon" :alt="item.title" class="scenario-icon" />
          <div class="scenario-content">
            <div class="scenario-title">{{ item.title }}</div>
            <div class="scenario-description">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 核心优势 -->
    <div class="core" id="core">
      <div class="title">核心优势</div>
      <div class="title-desc">多元场景，服务可用，内容可信，价格可控</div>
      <div class="core-wrap">
        <div
          class="core-card"
          v-for="(item, i) in core"
          :key="i"
          :style="{ backgroundImage: `url(${item.url})` }"
        >
          <p class="core-title">{{ item.title }}</p>
          <p class="info">{{ item.info }}</p>

          <div class="core-info" v-for="(d, index) in item.desc" :key="index">
            <img src="/img/core-right.png" alt="" />
            <span class="core-desc">{{ d }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品能力 -->
    <div class="product" id="product">
      <div class="title">产品能力</div>
      <div class="title-desc">
        一问多模回答，文生图、图生图、图生视频、文生音乐等多模态能力
      </div>

      <div class="nav-bar">
        <div
          class="nav-item"
          :class="product.title === item.title ? 'active' : ''"
          v-for="item in navItems"
          :key="item.title"
          @click="clickNavItem(item)"
        >
          <img :src="item.icon" :alt="item.title" class="nav-icon" />
          <span class="nav-title">{{ item.title }}</span>
        </div>
      </div>
      <div class="nav-content">
        <div>
          <!-- <div class="p-title">{{ product.title }}</div> -->
          <div class="p-desc">{{ product.desc }}</div>
        </div>
        <img class="product-img" :src="product.img" alt="" />
        <div class="try-btn" @click="toLiuhe()">立即体验</div>
      </div>
    </div>
    <!-- banner2 -->
    <div class="banner2">
      <img class="logo" src="/img/logo3.png" alt="" />
      <div class="title">六合AI融合模型，一站式AI学习与实训平台</div>
      <div class="title-desc">全栈AI，学习实训一站到位</div>
      <div class="try-btn-wrap">
        <div class="try-btn" @click="toLiuhe()">立即体验</div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="contact-us" id="contactUs">
      <div class="title" style="color: #fff; font-size: 20px">联系我们</div>
      <div class="title-desc" style="color: #fff; font-size: 14px">
        留下您的联系方式，我们专业顾问会尽快与您联系
      </div>
      <div class="contact">
        <form class="contact-form" @submit.prevent="submitForm">
          <div class="form-wrap">
            <div class="form-group">
              <label for="name" v-if="!form.name">
                <span>*</span>
                姓名
              </label>
              <input type="text" id="name" v-model="form.name" required />
            </div>
            <div class="form-group">
              <label for="phone" v-if="!form.phone">
                <span>*</span>联系电话
              </label>
              <input type="phone" id="phone" v-model="form.phone" required />
            </div>
            <div class="form-group">
              <label for="wx" v-if="!form.wx"><span></span>微信 </label>
              <input type="wx" id="wx" v-model="form.wx" />
            </div>

            <div class="form-group">
              <label for="describe" v-if="!form.describe"
                ><span>*</span>需求描述</label
              >
              <textarea
                id="describe"
                v-model="form.describe"
                required
              ></textarea>
            </div>
          </div>
          <button class="try-btn" type="submit">提交</button>
        </form>
        <div class="contact-info">
          <p>
            <img
              src="/img/addr.png"
              alt=""
            />北京总部：北京市海淀区创业路6号自主创新大厦
          </p>
          <p><img src="/img/mail.png" alt="" />邮编：100085</p>
          <p>
            <img src="/img/mail2.png" alt="" />商业咨询：service@changjing.ai
          </p>
          <p><img src="/img/mail2.png" alt="" />媒体垂询：pr@changjing.ai</p>
          <p><img src="/img/phone.png" alt="" />联系电话：010-86463634</p>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="footer">
      <div class="footer-content">
        <div class="footer-nav">
          <span class="link-wrap" v-for="nav in navs">
            <a v-if="nav.id" class="footer-link" :href="nav.id">{{
              nav.name
            }}</a>
            <span v-else class="footer-link">{{ nav.name }}</span>
          </span>
        </div>
      </div>
      <div class="footer-bottom">
        <p>Copyright ©2024 liuheai.com All rights reserved</p>
        <p>
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            style="color: #2d8cf0; text-decoration: none"
            >苏ICP备2023018419号-5</a
          >
        </p>
        <img class="logo2" src="/img/logo2.png" alt="" />
      </div>
    </div>

    <!-- 侧边面板 -->
    <el-drawer v-model="drawer" title="" :with-header="false">
      <div class="navs">
        <span v-for="nav in navs">
          <a
            v-if="nav.id"
            class="nav"
            @click.prevent="scrollToAnchor(nav.id)"
            href="#"
            >{{ nav.name }}</a
          >
          <a v-if="nav.href" class="nav" target="_blank" :href="nav.href">{{
            nav.name
          }}</a>
        </span>
      </div>
    </el-drawer>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { httpPost } from "@/utils/http";
import { ElMessage } from "element-plus";
const drawer = ref(false);
const navs = ref([
  { name: "融合大模型", id: "#model" },
  { name: "AI课程", id: "#AIcourse" },
  { name: "企业服务", id: "#service" },
  { name: "应用场景", id: "#scenario" },
  { name: "核心优势", id: "#core" },
  { name: "产品能力", id: "#product" },
  { name: "联系我们", id: "#contactUs" },
  { name: "AI工具箱", id: "", href: "gpt.changjing.com.cn" }
]);
const stats = [
  { value: "1.5", unit: "亿+", label: "消息对话" },
  { value: "2500", unit: "万+", label: "创意绘画" },
  { value: "200", unit: "万+", label: "生成视频" },
  { value: "600", unit: "万+", label: "创作音乐" },
  { value: "50", unit: "万+", label: "用户在使用" }
];
const courses = [
  { name: "ChatGPT", url: require("../assets/images/mobile/course8.png") },
  { name: "AI创作", url: require("../assets/images/mobile/course4.png") },
  { name: "AIGC办公提效", url: require("../assets/images/mobile/course5.png") },
  { name: "AIGC行业应用", url: require("../assets/images/mobile/course6.png") },
  { name: "大语言模型", url: require("../assets/images/mobile/course7.png") }
];
const members = [
  {
    description:
      "· 清华大学人工智能产业研究院博士 </br>· 前百度智慧城市总经理 </br>· 智慧交通负责人",
    image: require("../assets/images/course-tec4.png")
  },
  {
    description:
      "· 北京邮电大学硕士</br>· 商业分析领域知名专家和领军人物</br>· 前百度智慧城市研究院执行院长",
    image: require("../assets/images/course-tec5.png")
  },
  {
    description:
      "· 西安理工大学硕士 </br>· 资深互联网营销专家 </br>· 教育行业知名专家 </br>· 前百度聚屏商业产品联合创始人",
    image: require("../assets/images/course-tec6.png")
  },
  {
    description:
      "· 北京航空航天大学知名校友</br>· 亿景智联&秒影工场CTO</br>· 人工智能多模态技术专家",
    image: require("../assets/images/course-tec3.png")
  },
  {
    description:
      "· 意大利博洛尼亚美术学院硕士</br>· AIGC短视频创意与营销专家</br>· 前微盟集团创意研究院负责人",
    image: require("../assets/images/course-tec2.png")
  },
  {
    description: "· AIGC资深产品经理</br>· 亿景智联&秒影工场 CPO",
    image: require("../assets/images/course-tec1.png")
  }
];
const core = [
  {
    title: "解决工作难题",
    url: require("../assets/images/mobile/core1.png"),
    info: "工作对话",
    desc: ["语言大模型支持", "全场景工作AI对话", "零代码创建角色应用"]
  },
  {
    title: "提升工作效率",
    url: require("../assets/images/mobile/core2.png"),
    info: "内容创作",
    desc: ["自动化生成高质量的文案和图片内容，显著提升工作效率"]
  },
  {
    title: "自有知识训练",
    url: require("../assets/images/mobile/core3.png"),
    info: "知识库训练",
    desc: ["支持企业产品及服务等信息上传知识库，训练企业私有模型"]
  },
  {
    title: "多元化应用场景",
    url: require("../assets/images/mobile/core4.png"),
    info: "开放API",
    desc: ["API灵活对接", "可选择通过接入微信或企微等客户沟通平台"]
  }
];
const features = [
  {
    icon: "/img/card1.png",
    title: "政府轻模型",
    description:
      "全面剖析我国近年来在数字经济、人工智能+、数据要素X、新质生产力等关键领域的政策动态，深入解读政策精髓，系统整理政策要点。"
  },
  {
    icon: "/img/card2.png",
    title: "医疗轻模型",
    description:
      "专注医疗行业前沿，辅助医院打造人才培育体系、专业撰写医疗健康领域报告、深度剖析顶级医疗文献，提供科研成果申报全流程指导。"
  },
  {
    icon: "/img/card3.png",
    title: "教育轻模型",
    description:
      "专注教育行业发展政策动态趋势，深入解读教育行业政策精髓，系统整理政策要求，为从业者提供精准的政策理解辅助。"
  },
  {
    icon: "/img/card4.png",
    title: "金融轻模型",
    description:
      "专注金融行业前沿，深度分析金融市场数据，辅助风险评估，提高交易决策精度，加强投资策略和业务合规性。"
  },
  {
    icon: "/img/card5.png",
    title: "舆情轻模型",
    description:
      "从互联网收集公共意见，揭示舆论走向，为企业和政策制定者提供战略响应指导。"
  },
  {
    icon: "/img/card6.png",
    title: "文旅轻模型",
    description:
      "捕捉旅游消费趋势，分析文化活动影响，助推目的地营销和旅游产品创新。"
  },
  {
    icon: "/img/card7.png",
    title: "传媒轻模型",
    description:
      "汇聚新闻传播效果和受众反馈，指导内容生产，优化媒介平台管理，增强影响力。"
  },
  {
    icon: "/img/card8.png",
    title: "电商轻模型",
    description:
      "结合电商交易、用户行为数据，指导销售策略，提升顾客购物体验，优化供应链效率。"
  }
];
const navItems = ref([
  {
    icon: "/img/icon1.png",
    title: "多模型AI对话",
    children: {
      title: "多模型AI对话",
      desc: "一站式对话平台，接入海内外数十个AI对话大语言模型，快速响应找到你要的答案",
      img: "/img/product1.png"
    }
  },
  {
    icon: "/img/icon2.png",
    title: "AI文生图",
    children: {
      title: "AI文生图",
      desc: "一站式绘画平台，接入数个海内外绘画大模型，提供不同风格的文生图能力",
      img: "/img/product2.png"
    }
  },
  {
    icon: "/img/icon3.png",
    title: "AI图生图",
    children: {
      title: "AI图生图",
      desc: "接入数个海内外绘画大模型，通过参考图、角色一致性控制等方式进行图生图创作",
      img: "/img/product3.png"
    }
  },
  {
    icon: "/img/icon4.png",
    title: "AI图生成视频",
    children: {
      title: "AI图生成视频",
      desc: "接入数个海内外视频大模型，通过参考图和文字描述的方式来生成动效视频",
      img: "/img/product4.png"
    }
  },
  {
    icon: "/img/icon5.png",
    title: "AI文生音乐",
    children: {
      title: "AI文生音乐",
      desc: "接入数个海内外音乐大模型，通过文字描述，结合不同的风格设置即可创作属于你自己的原创歌曲",
      img: "/img/product5.png"
    }
  }
]);
const product = ref(navItems.value[0].children);
const clickNavItem = (item) => {
  product.value = item.children;
};
const form = ref({
  name: "",
  phone: "",
  wx: "",
  describe: ""
});

const submitForm = () => {
  httpPost("/api/contact/us", form.value)
    .then((res) => {
      ElMessage.success("提交成功");
    })
    .catch((err) => {
      ElMessage.error("提交失败");
    });
};
const toLiuhe = () => {
  window.open("https://chat.liuheai.com/chat", "_blank");
};
const isFixed = ref(false);
const handleScroll = () => {
  let scrollY = window.scrollY;
  isFixed.value = scrollY > 100 ? true : false;
};
const scenario = ref([
  {
    icon: "/img/mobile/scenario1.png",
    title: "聊天对话",
    description: "一个问题，多个模型对话回答，快速得到你想要的答案"
  },
  {
    icon: "/img/mobile/scenario2.png",
    title: "写作创作",
    description: "各类写作风格秒生成，效率如神助，妙手生花，让灵感瞬间实现"
  },
  {
    icon: "/img/mobile/scenario4.png",
    title: "阅读分析",
    description: "智能搜索分析总结，上下文智能解读，PDF文本分析无忧"
  },
  {
    icon: "/img/mobile/scenario3.png",
    title: "图片创作",
    description: "用文字即可生成真实精美的图片，支持高达4倍精度的高清图片生成"
  },
  {
    icon: "/img/mobile/scenario5.png",
    title: "音乐创作",
    description: "用文字即可创作不同风格的音乐歌曲，支持长达3分钟的音乐歌曲生成"
  },
  {
    icon: "/img/mobile/scenario6.png",
    title: "视频创作",
    description: "用文字描述、图片即可转换成动态视频，支持多尺寸不同效果展示"
  }
]);
const scrollToAnchor = (selector) => {
  drawer.value = false;
  const element = document.querySelector(selector);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});
</script>

<style lang="stylus">
@import '../assets/css/mobile.styl';
@import '../assets/css/main.styl';
</style>
